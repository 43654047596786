import React from "react";
import Icon from "../custom-widgets/icon";
import ButtonHoverIcon from "./button-hover-icon";
import ChatButton from "./chat-button";
import useToggleMinimizeChat from "../../hooks/use-toggle-minimize-chat";

const ChatSection = ({ chatSectionData, children }) => {
  const { title, description, chatWaltButtonText, chatWaltButtonId, customerServiceButtons } = chatSectionData;
  const chatWaltButtonData = {
    className: "btn-link",
    icon: "../../images/icons/chat-walt.svg",
    iconHover: "../../images/icons/chat-walt-hover.svg"
  };

  const { toggleChat, chatLoading } = useToggleMinimizeChat();

  return (
    <section className="container pt-2">
      <div className="row">
        <div className="col col-lg-6 mb-4">
          <div className="border-radius-12 bg-green-10 px-4 pt-3 pb-4 h-100">
            <h2 className="text-green-60 font-weight-semi-bold">
              <Icon class="mr-2" lib="fas" name="comment-smile" />
              <span className="ml-1">{title}</span>
            </h2>
            <p className="font-weight-semi-bold">{description}</p>
            <ButtonHoverIcon
              id={chatWaltButtonId}
              type="button"
              onClick={toggleChat}
              chatLoading={chatLoading}
              text={chatWaltButtonText}
              {...chatWaltButtonData}
            />
          </div>
        </div>
        <div className="col col-lg-6 mb-4">
          {customerServiceButtons.map((btn) => (
            <ChatButton {...btn} />
          ))}
        </div>
      </div>
      {children}
    </section>
  );
};

export default ChatSection;
