import React, { useState } from "react";
import styles from "./button-hover-icon.module.scss";

const ButtonHoverIcon = ({ icon, iconHover, text, subtext, className, url, id, type, onClick, chatLoading }) => {
  const [hover, setHover] = useState(false);

  if (type === "button") {
    return (
      <button
        id={id}
        class={`btn d-flex align-items-center ${styles.btnHoverIcon} ${className}`}
        type={type}
        onClick={() => onClick()}
        disabled={chatLoading}
      >
        <div className={`d-flex align-items-center justify-content-center ${styles.iconContainer}`}>
          <img alt="" src={hover ? iconHover : icon} style={{ height: "42px" }} />
        </div>
        <div>
          <h4 className="font-weight-bold m-0">{text}</h4>
          {subtext && <div className="text-left">{subtext}</div>}
        </div>
      </button>
    );
  }

  return (
    <a
      id={id}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      className={`btn d-flex align-items-center ${styles.btnHoverIcon} ${className}`}
      href={url}
    >
      <div className={`d-flex align-items-center justify-content-center ${styles.iconContainer}`}>
        <img alt="" src={hover ? iconHover : icon} style={{ height: "42px" }} />
      </div>
      <div>
        <h4 className="font-weight-bold m-0">{text}</h4>
        {subtext && <div className="text-left">{subtext}</div>}
      </div>
    </a>
  );
};

export default ButtonHoverIcon;
